"use strict";

angular.module("informaApp")
    .service("ChartMapper", ["ConstantsSvc", function (ConstantsSvc) {
        var emptyGroupItem = {
            loa: {
                phase1: 0,
                phase2: 0,
                phase3: 0,
                ndabla: 0
            }
        };
        emptyGroupItem.pos = _.merge({}, emptyGroupItem.loa);
        emptyGroupItem.duration = _.merge({}, emptyGroupItem.loa);
        emptyGroupItem.numberOfTransitions = {
            total: _.merge({}, emptyGroupItem.loa)
        };

        function getUniqueItems(data, targetItems) {
            var result = [];

            var groups = getGroups(data);

            for (var i = 0; i < data.length; i++){
                for (var j = 0; j < data[i].drugIndications.length; j++){
                    var x =  data[i].drugIndications[j];

                    result.push({
                        id: x.sourceId,
                        label: x.name,
                        groups:fillGroup(data, groups, x.sourceId)
                    });
                }
            }

            for (var i = 0; i < targetItems.length; i++){
                result.push({
                    label: targetItems[i].name,
                    groups:fillGroup(data, groups, -1)
                });
            }

            return _.uniqBy(result, function (x) {
                return x.label;
            });
        }

        function fillGroup(data, group, sourceId) {
            var newGroup = _.merge([], group);

            for (var i = 0; i < newGroup.length; i++){
                newGroup[i].data = getGroupData(data, newGroup[i].name, sourceId);
            }

            return newGroup;
        }

        function getGroups(data){
            return data.map(function (x) {
                return {
                    name: getGroupName(x.startDate, x.endDate, x.allData),
                    data: []
                };
            })
        }

        function getGroupData(data, groupName, sourceId) {
            var group = _.find(data, function (x) {
                return getGroupName(x.startDate, x.endDate, x.allData) === groupName;
            });

            var item = _.find(group.drugIndications, function (x) {
                return x.sourceId == sourceId;
            });

            return item ? mapGroupItem(item) : mapGroupItem(emptyGroupItem);
        }


        function mapGroupItem(item) {
            var result = [];

            var x = _.merge([], ConstantsSvc.Phases);
            var loa = mapPhases(item.loa);
            var pos = mapPhases(item.pos);
            var duration = mapPhases(item.duration);
            var numberOfTransitions = mapPhases(item.numberOfTransitions.total);

            for (var i = 0; i < x.length; i++){
                result.push({
                    x: x[i],
                    loa: loa[i],
                    pos: pos[i],
                    duration: duration[i],
                    numberOfTransitions: numberOfTransitions[i]
                });
            }

            return result;
        }

        function mapPhases(item) {
            var phases = ['phase1', 'phase2', 'phase3', 'ndabla'];

            return [item[phases[0]], item[phases[1]], item[phases[2]], item[phases[3]]];
        }

        function getGroupName(startDate, endDate, allData) {
            var startYear = moment(startDate, ConstantsSvc.Dates.queryFormat).year();
            var endYear = moment(endDate, ConstantsSvc.Dates.queryFormat).year();
            return allData ? 'All' : startYear + (endYear == startYear ? "" : (" - " + endYear));
        }

        return {
            map: function (data, targetItems) {
                _.forEach(data, function (x) {
                    for (var i = 0; i < x.drugIndications.length; i++){
                        var target = _.find(targetItems, function (t) {
                            return t.id === x.drugIndications[i].sourceId;
                        });

                        x.drugIndications[i].name = target ? target.name : x.drugIndications[i].name;
                    }
                });

                var result = getUniqueItems(data, targetItems);

                return result;
            }
        }
    }]);
